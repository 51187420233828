import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.config({
   limitCallbacks: true
});

gsap.utils.toArray(".lazy").forEach(image => {

   let newSRC = image.dataset.src,
      newImage = document.createElement("img"),

      loadImage = () => {
         newImage.onload = () => {
            newImage.onload = null; // avoid recursion
            newImage.src = image.src; // swap the src
            image.src = newSRC;
            // place the low-res version on TOP and then fade it out.
            gsap.set(newImage, {
               position: "absolute",
               top: image.offsetTop,
               left: image.offsetLeft,
               width: image.offsetWidth,
               height: image.offsetHeight
            });
            image.parentNode.appendChild(newImage);
            gsap.to(newImage, {
               opacity: 0,
               onComplete: () => {
                  newImage.parentNode.removeChild(newImage);
                  image.removeAttribute("data-src"); // remove "data-src" attribute if image is loaded
               }
            });
            st && st.kill();
         }
         newImage.src = newSRC;
      },

      st = ScrollTrigger.create({
         trigger: image,
         start: "-70% bottom",
         onEnter: loadImage,
         onEnterBack: loadImage // make sure it works in either direction
      });
});

//gsap.utils.toArray(".lazy-video").forEach(video => {

//   let newSRC = video.dataset.src,
//      newVideo = document.createElement("video"),

//      loadVideo = () => {
//         newVideo.onload = () => {
//            newVideo.onload = null; // avoid recursion
//            newVideo.src = video.src; // swap the src
//            video.src = newSRC;
//            // place the low-res version on TOP and then fade it out.
//            gsap.set(newVideo, {
//               position: "absolute",
//               top: video.offsetTop,
//               left: video.offsetLeft,
//               width: video.offsetWidth,
//               height: video.offsetHeight
//            });
//            video.parentNode.appendChild(newVideo);
//            gsap.to(newVideo, {
//               opacity: 0,
//               onComplete: () => {
//                  newVideo.parentNode.removeChild(newVideo);
//                  video.removeAttribute("data-src"); // remove "data-src" attribute if image is loaded
//               }
//            });
//            st && st.kill();
//         }
//         newVideo.src = newSRC;
//      },

//      st = ScrollTrigger.create({
//         trigger: video,
//         start: "-50% bottom",
//         onEnter: loadVideo,
//         onEnterBack: loadVideo // make sure it works in either direction
//      });
//});

//document.addEventListener("DOMContentLoaded", function () {
//   var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy-video"));

//   if ("IntersectionObserver" in window) {
//      var lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
//         entries.forEach(function (video) {
//            if (video.isIntersecting) {
//               for (var source in video.target.children) {
//                  var videoSource = video.target.children[source];
//                  if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
//                     videoSource.src = videoSource.dataset.src;
//                  }
//               }

//               video.target.load();
//               video.target.classList.remove("lazy-video");
//               lazyVideoObserver.unobserve(video.target);
//            }
//         });
//      });

//      lazyVideos.forEach(function (lazyVideo) {
//         lazyVideoObserver.observe(lazyVideo);
//      });
//   }
//});
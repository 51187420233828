import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
//let mm = gsap.matchMedia();

gsap.to(".cta-ill", {
   scale: 1,
   duration: 3,
   ease: "power3.out",
   scrollTrigger: {
      trigger: '.cta-title',
      //scroller: scroller,
      start: 'top bottom',
      end: '+=30%',
      //scrub: true,
      //markers: true,
   }
});
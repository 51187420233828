import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitType from 'split-type';
gsap.registerPlugin(ScrollTrigger);


//typewriter
setTimeout(function () {
   const typewriterItems = gsap.utils.toArray(".typewriter-item");

   typewriterItems.forEach((text, i) => {
      new SplitType(text, {
         types: "chars",
         tagName: "span"
      });
   });



   let typewriterTl = gsap.timeline({
      paused: true,
      repeat: -1,
      yoyo: true,
      repeatDelay: 0.5,
   });
   gsap.set('.typewriter-item-2 .char, .typewriter-item-3 .char, .typewriter-item-4 .char, .typewriter-item-5 .char, .typewriter-item-2, .typewriter-item-3, .typewriter-item-4, .typewriter-item-5', {
      display: "none",
   });


   typewriterTl.to(".typewriter-item-1 .char", {
         display: "none",
         duration: 1.8,
         stagger: {
            from: "end",
            amount: 0.9
         }
      })
      .set('.typewriter-item-1', {
         display: "none",
      })
      .set('.typewriter-item-2', {
         display: "inline-block",

      })
      .to(".typewriter-item-2 .char", {
         display: "inline-block",
         duration: 1.8,
         repeat: 1,
         yoyo: true,
         repeatDelay: 0.5,
         delay: 2,
         stagger: {
            amount: 0.9
         }
      })
      .set('.typewriter-item-2', {
         display: "none",
      })
      .set('.typewriter-item-3', {
         display: "inline-block",

      })
      .to(".typewriter-item-3 .char", {
         display: "inline-block",
         duration: 1.8,
         repeat: 1,
         yoyo: true,
         repeatDelay: 0.5,
         delay: 2,
         stagger: {
            amount: 0.9
         }
      })
      .set('.typewriter-item-3', {
         display: "none",
      })
      .set('.typewriter-item-4', {
         display: "inline-block",

      })
      .to(".typewriter-item-4 .char", {
         display: "inline-block",
         duration: 1.8,
         repeat: 1,
         yoyo: true,
         repeatDelay: 0.5,
         delay: 2,
         stagger: {
            amount: 0.9
         }
      })
      .set('.typewriter-item-4', {
         display: "none",
      })
      .set('.typewriter-item-5', {
         display: "inline-block",

      })
      .to(".typewriter-item-5 .char", {
         display: "inline-block",
         duration: 1.8,
         repeat: 1,
         yoyo: true,
         repeatDelay: 0.5,
         delay: 2,
         stagger: {
            amount: 0.9
         }
      })
      .set('.typewriter-item-5', {
         display: "none",
      })
      .set('.typewriter-item-1', {
         display: "inline-block",

      })
      .to(".typewriter-item-1 .char", {
         display: "inline-block",
         duration: 1.8,
         delay: 2,
         stagger: {
            amount: 0.9
         }
      });

   ScrollTrigger.create({
      trigger: ".cta-title",
      //scroller: scroller,
      start: "top bottom",
      //animation: typewriterTl,
      onEnter: elements => {
         typewriterTl.play();
      },
      onLeave: elements => {
         typewriterTl.pause();
      },
      onEnterBack: elements => {
         typewriterTl.play();
      },
      onLeaveBack: elements => {
         typewriterTl.pause();
      },
   });
}, 3000);
//typewriter END